/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { type Resources } from 'one-js-shared/src/api/resources';
import BGlang from 'one-js-web/src/locale/bg/lang.json';
import ENlang from 'one-js-web/src/locale/en/lang.json';
import apiClient, { APIContext } from 'one-js-web/src/apiClient';
import authAppRegistryProvider from 'one-js-web/src/features/auth/appRegistryProvider';
import { queryClientAppRegistryProvider } from 'one-js-web/src/lib/react-query';
import rootComponents from 'one-js-web/src/rootComponents';
import { AppRegistry } from 'phputils-js/src/react_';
import type { AuthData } from 'phputils-js/src/websocket/SessionBackend';
import WebSocketClientProxy from 'phputils-js/src/websocket/WebSocketClientProxy';
import { WebSocketClientOptions } from 'speed-js-core/src/websocket/WebSocketClient';
import reduxStore from 'one-js-web/src/reduxStore';
import ReactAppsRegistry from './ReactAppsRegistry';

declare global {
    interface Window {
        WebSocketClient: typeof WebSocketClientProxy;
        appRegistry: AppRegistry<Resources>,
        DEFAULT_LANGUAGE: string,
        _ws?: {
            url: string,
            auth?: AuthData,
            options?: WebSocketClientOptions,
        }
    }
}

let wsClient;
if (window._ws) {
    wsClient = WebSocketClientProxy.connect(window._ws.url, window._ws.auth, window._ws.options);
}
const detectedLanguage: string = window.language?.replace('_', '-') || 'en-GB';

const appRegistry = new AppRegistry<Resources>(
    wsClient,
    reduxStore,
    {
        client: apiClient,
        ContextProvider: APIContext.Provider,
    },
    {
        locale: detectedLanguage,
        messages: detectedLanguage === 'bg-BG' ? BGlang : ENlang,
    },
    [authAppRegistryProvider, queryClientAppRegistryProvider]);

Object.keys(rootComponents).forEach((componentName) => {
    appRegistry.registerAppComponent(componentName, rootComponents[componentName]);
});

// Set globals
window.WebSocketClient = WebSocketClientProxy;
window.appRegistry = appRegistry;

// These are all the different apps that are loaded trough the app registry
// Initialise initial app components
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
ReactAppsRegistry.forEach((reactAppName: string) => {
    const renderComponentsOnLoadScript = document.getElementById(reactAppName);

    if (renderComponentsOnLoadScript && renderComponentsOnLoadScript.textContent) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const renderComponentsOnLoad: {
            [domId: string]: {
                componentName: string,
                props: Record<string, undefined>,
            },
        } = JSON.parse(renderComponentsOnLoadScript.textContent);

        Object.keys(renderComponentsOnLoad).forEach((domId) => {
            const data = renderComponentsOnLoad[domId];

            const domNode = document.getElementById(domId);
            if (!domNode) {
                console.error(`Cannot initialise app - no node found with id ${domId}`);
                return;
            }

            appRegistry.renderApp(domNode, data.componentName, data.props);
        });
    }
});
